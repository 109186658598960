
import { defineComponent, onMounted } from "vue";
import KTModalCard from "@/components/cards/Card.vue";
import AddUserModal from "@/components/modals/forms/AddUserModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "add-user",
  components: {
    KTModalCard,
    AddUserModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Add User", ["Apps", "User"]);
    });

    return {};
  },
});
